import React, {useRef, forwardRef, useState, memo, useEffect} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { motion, useInView, useScroll, useMotionValueEvent } from "framer-motion";
import SplashVisualization from './SplashVisualization';
import ProductionNetwork from './ProductionNetwork';
import InputCategorization from './InputCategorization';
import IndustryOutputMap from './IndustryOutputMap';
import Tetris from './Tetris';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';
import headshot from './stu-headshot.jpg';
import diagramSingleIndustry from './diagram_single_industry.svg';
import diagramComplements from './diagram_complements.svg';
import diagramSubstitutes from './diagram_substitutes.svg';
import mpStudyAreas from './mp_study_areas_overview.jpg';
import mpResLidar from './mp_residential_lidar_data_points.jpg';
import mpResTIN from './mp_residential_tin_model.jpg';
import mpResDSM from './mp_residential_digital_surface_model.jpg';
import mpResSolarRadiation from './mp_residential_solar_radiation.jpg';
import mpResNDVI from './mp_residential_ndvi.jpg';
import mpResSuitableArea from './mp_residential_suitable_pv_area.jpg';
import isoneWindOutputWindSpeed from './isone_windoutput_windspeed.svg';
import isoneWindOutputWindGustSpeed from './isone_windoutput_windgustspeed.svg';
import isoneSolarOutputFairWeather from './isone_solaroutput_fairweather.svg';
import isoneNetImportsWindOutput from './isone_netimports_windoutput.svg';
import isoneNetImportsSolarOutput from './isone_netimports_solaroutput.svg';
import isoneExcel from './isone_windsolar_weather_netimports_exploration.xlsx';
import greenButtonActors from './green_button_actors.png';
import compareMyEnergy from './compare_my_energy.jpg';
import stylesheetScreenshot1 from './stylesheet_screenshot_1.png';
import stylesheetScreenshot2 from './stylesheet_screenshot_2.png';
import graphData from './production_network.json';
import categoryData from './input_categorization.csv';
import thumbnailProductionNetwork from './production_network_thumbnail.png';
import thumbnailInputCategorization from './input_categorization_thumbnail.png';
import thumbnailTetris from './tetris_thumbnail.png';
import thumbnailProductionMap from './production_map_thumbnail.png';
import thumbnailConsumingAPI from './consuming_your_own_apis_thumbnail.png';

function TopBar(props) {
  return (
    <div className="menu_container">
      <div className="menu">
        <span className="topbar_name"><a href="/">STUART ILER</a></span>
        <input className="side-menu" type="checkbox" id="side-menu"/>
        <ul className="menu-list">
          <HashLink to="/about"><li className="menu-list-item">ABOUT</li></HashLink>
          <HashLink to="/skills"><li className="menu-list-item">SKILLS</li></HashLink>
          <HashLink to="/tools"><li className="menu-list-item">TOOLS</li></HashLink>
          <HashLink to="/portfolio"><li className="menu-list-item">PORTFOLIO</li></HashLink>
          <HashLink to="/contact"><li className="menu-list-item">CONTACT</li></HashLink>
        </ul>
        <label className="hamb" htmlFor="side-menu"><span className="hamb-line"></span></label>
      </div>
    </div>
  );
}

function BottomBar(props) {
  return (
    <div className="bottom_bar">
      <p><b>&copy; 2025 Stuart Iler / Iler Consulting LLC</b></p>
    </div>
  );
}

const Splash = memo(function Splash(props) {
    return (
      <div id="splash_background">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="titles">
          <p><b>Data Scientist</b></p>
          <p><b>|</b></p>
          <p><b> Economist</b></p>
          <p><b>|</b></p>
          <p><b> Technical Communicator</b></p>
        </div>
        <div className="splash_visualization">
          <SplashVisualization/>
        </div>
      </div>
    )
})

function FadeInDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          opacity: isInView ? 1 : 0,
          transition: "opacity 1.5s"
        }}
      >
        {children}
      </div>
  );
}

function FlyInLeftDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(-100px)",
          opacity: isInView ? 1 : 0,
          transition: "transform 1s, opacity 1s"
        }}
      >
        {children}
      </div>
  );
}

function FlyInRightDiv({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
      <div
        ref={ref}
        style={{
          transform: isInView ? "none" : "translateX(100px)",
          opacity: isInView ? 1 : 0,
          transition: "transform 1s, opacity 1s"
        }}
      >
        {children}
      </div>
  );
}

const About = forwardRef(function About(props, ref) {
    return (
      <div id="about_background" ref={ref}>
        <div id="about" className="section_ID"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <FadeInDiv><div className="intro_text">
          <img src={headshot} alt="Photo of Stuart Iler."/>
          <div>
            <p>Welcome! I'm a mission-driven data and analytical consultant with
            a background at the intersection of data science, economics, and
            technical communications. My passion in serving clients is twofold.
            First, I love leveraging strategic thinking, visualization, and
            analysis to help clients make the most of their data, understand
            past progress, and plan for the future. Second, I love bridging the
            gap between technical content&mdash;whether data,
            analyses, concepts, or software&mdash;and audiences that need to understand,
            learn from, and/or make decisions based on that content.</p>
            <div className="skill_highlights">
              <p><b>DATA</b></p>
              <ul>
                <li><b>|</b> Visualization</li>
                <li><b>|</b> Dashboards</li>
                <li><b>|</b> Analytics</li>
              </ul>
            </div>
            <div className="skill_highlights">
              <p><b>TECHNICAL</b></p>
              <ul>
                <li><b>|</b> Writing</li>
                <li><b>|</b> Training</li>
              </ul>
            </div>
            <p>My previous work experience spans nonprofit, academic,
            government, and private contexts, including as an analytics manager,
            data scientist, freelance writer, and software engineer. I have a
            strong analytical and technical foundation with a Ph.D. in Public
            Policy from Harvard University (<a
            href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf"
            target="_blank" rel="noreferrer">dissertation</a>), a Master of
            Environmental Management degree from Duke University (<a
            href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf"
            target="_blank" rel="noreferrer">thesis</a>), and a B.S. in Computer
            Science from U.C. San Diego. </p>
            <p>For details about my previous positions, education, and
            publications, please see my <a href="https://www.linkedin.com/in/stuartiler" target="_blank" rel="noreferrer">LinkedIn profile</a>.
            For sample projects, writing, and code, please see below as well as
            my <a href="https://github.com/stuartiler/" target="_blank" rel="noreferrer">GitHub profile</a>.</p>
          </div>
        </div></FadeInDiv>
      </div>
    );
})

const Skills = forwardRef(function Skills(props, ref) {
  return (
    <div id="skills_background" ref={ref}>
      <div id="skills" className="section_ID"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <FlyInLeftDiv><div className="header_statement header_left">Leveraging a
      broad technical background to educate, inform, assess progress, and drive
      decision-making.</div></FlyInLeftDiv>
      <FadeInDiv><div className="header_substatement header_left">I have been
      the lead technical professional for multiple teams, responsible for
      setting strategic direction, ensuring datasets and analyses are used to
      advance organizational goals, and collaborating and communicating with
      professionals who have a range of technical and non-technical
      backgrounds.</div></FadeInDiv>
      <div className="skills">
        <FadeInDiv>
          <span className="icon"><FontAwesomeIcon icon={faPersonChalkboard} size="2x" /></span>
          <span>All my work has included a significant communications component,
          including translating technical, academic, and policy content for a
          variety of audiences ranging from the public to organizations'
          senior leadership. I have extensive writing experience as well as
          presentation, teaching, and training experience.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span  className="icon"><FontAwesomeIcon icon={faChartSimple} size="2x" /></span>
          <span>I have designed and created a spectrum of static and interactive
          data visualizations to illustrate relationships, communicate trends,
          and support decision-making. I have also taken the lead role in metric
          design and implementation, ranging from ideation of what information
          to track to designing processes to bring indicators to life.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span  className="icon"><FontAwesomeIcon icon={faMagnifyingGlassChart} size="2x" /></span>
          <span>I have advanced skills using statistical and machine learning
          techniques to extract insights from data and make predictions. I am
          well equipped to understand how clients are analyzing their data, to
          design and execute new analyses, and to aid in interpreting and
          communicating key takeaways.</span>
        </FadeInDiv>
        <FadeInDiv>
          <span  className="icon"><FontAwesomeIcon icon={faCity} size="2x" /></span>
          <span>I have leveraged a range of different datasets in various
          topical areas, including energy, air quality, employment,
          manufacturing, transportation, and more. I have expertise in economics
          and public policy with a focus on energy, climate, and sustainability.</span>
        </FadeInDiv>
      </div>
    </div>
  );
})

const Tools = forwardRef(function Tools(props, ref) {
  return (
    <div id="tools_background" ref={ref} >
      <div id="tools" className="section_ID"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <FlyInRightDiv><div className="header_statement header_right">Experience
      with a range of different tools.</div></FlyInRightDiv>
      <FadeInDiv><div className="header_substatement header_right">I am well
      equipped to pick the right tools for each project and to learn new tools
      when needed. My broad background also allows me to quickly understand how
      existing products and analyses have been built and
      structured.</div></FadeInDiv>
      <div className="tools">
        <FadeInDiv>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
            <span><b>Languages/Tools</b></span>
          </div>
          <div>I have development experience using a range of programming
          and markup languages, including <i>Python</i>, <i>R</i>, <i>HTML/CSS</i>, <i>JavaScript</i>, and <i>XML</i>.
          I also have experience with <i>APIs</i> and with <i>Git</i>.</div>
        </FadeInDiv>
        <FadeInDiv>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faChartLine} size="1x" /></span>
            <span><b>Visualization</b></span>
          </div>
          <div>I have created a variety of static and interactive visualizations
          and dashboards using <i>D3.js</i>, <i>Tableau</i>, <i>Power
          BI</i>, <i>R</i>, and <i>Python</i>, including maps, line charts,
          bar charts, dumbbell charts, treemaps, and network diagrams.</div>
        </FadeInDiv>
        <FadeInDiv>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faMap} size="1x" /></span>
            <span><b>GIS</b></span>
          </div>
          <div>I have used <i>GIS</i> to conduct analyses based on a variety of
          vector and raster data, such as lidar, satellite imagery, and
          building outlines. I have also worked with <i>ArcGIS Online</i> to
          showcase and share geospatial data.</div>
        </FadeInDiv>
        <FadeInDiv>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faDatabase} size="1x" /></span>
            <span><b>Databases</b></span>
          </div>
          <div>I have previous experience working with relational databases
          using <i>SQL</i>, including specific projects that have
          leveraged <i>PostgreSQL</i> and <i>Microsoft Access</i>.</div>
        </FadeInDiv>
      </div>
    </div>
  );
})

const Projects = forwardRef(function Projects(props, ref) {
    return (
      <div id="projects_background" ref={ref} >
        <div id="projects" className="section_ID"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <FlyInLeftDiv><div className="header_statement header_left">Creating a
        variety of projects for different audiences.</div></FlyInLeftDiv>
        <FadeInDiv><div className="header_substatement header_left">My project
        experience has encompassed a range of different presentation formats and
        target audiences. Below is a portfolio of sample projects that highlight
        some of my visualization, analytical, amd writing skills. As my
        background is in economics and public policy—specifically as
        it relates to energy and environment—many of these projects use data
        from, or focus on, those topical areas.</div></FadeInDiv>
        <div className="projects">
          <Link to="/industry_output">
            <FadeInDiv>
              <div className="thumbnail"><img src={thumbnailProductionMap}/></div>
              <div>What does the U.S. economy produce? A look at national and state data (D3.js, JavaScript, and Leaflet)</div>
            </FadeInDiv>
          </Link>
          <Link to="/production_network">
            <FadeInDiv>
              <div className="thumbnail"><img src={thumbnailProductionNetwork}/></div>
              <div>Exploring the U.S. economic production network (D3.js, JavaScript, and Python)</div>
            </FadeInDiv>
          </Link>
          <Link to="/input_categorization">
            <FadeInDiv>
            <div className="thumbnail"><img src={thumbnailInputCategorization}/></div>
            <div>Categorizing U.S. industries' use of petroleum products (D3.js, JavaScript, Python, and R)</div>
            </FadeInDiv>
          </Link>
          <Link to="/community_solar">
            <FadeInDiv>
            <div className="thumbnail"><img src={mpResSuitableArea}/></div>
            <div>Assessing the potential for community solar in Durham, North Carolina (ArcGIS)</div>
            </FadeInDiv>
          </Link>
          <Link to="/isone_weather">
            <FadeInDiv>
            <div className="thumbnail"><img src={isoneWindOutputWindSpeed}/></div>
            <div>Exploring New England wind and solar power as it relates to weather and net imports (Excel)</div>
            </FadeInDiv>
          </Link>
          <Link to="/green_button">
            <FadeInDiv>
            <div className="thumbnail"><img src={greenButtonActors}/></div>
            <div>How-to guide: Leveraging Green Button data for energy consumption apps (XML and APIs)</div>
            </FadeInDiv>
          </Link>
          <Link to="/consuming_your_own_apis">
            <FadeInDiv>
            <div className="thumbnail"><img src={thumbnailConsumingAPI}/></div>
            <div>The benefits of consuming your own APIs: An interview with NPR (APIs)</div>
            </FadeInDiv>
          </Link>
          <Link to="/tetris">
            <FadeInDiv>
            <div className="thumbnail"><img src={thumbnailTetris}/></div>
            <div>For fun: Tetris (D3.js and JavaScript)</div>
            </FadeInDiv>
          </Link>
        </div>
      </div>
    );

});

const Contact = forwardRef(function Contact(props, ref) {
    return (
      <div id="contact_background" ref={ref} >
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="contact" className="section_ID"></div>
        <FadeInDiv><div className="header_block">
          <span className="section_header">
            <p>CONTACT</p>
          </span>
          <span className="section_subheader">
            <p>If you're interested in working with me or learning more about
            my projects, please contact me on <a href="https://www.linkedin.com/in/stuartiler" target="_blank" rel="noreferrer">LinkedIn</a>.
            Thank you for visiting!</p>
          </span>
        </div></FadeInDiv>
        <div className="spacer"></div>
      </div>
    );
})

function MainPage(props) {

  const refAbout = useRef(null);
  const refSkills = useRef(null);
  const refTools = useRef(null);
  const refProjects = useRef(null);
  const refContact = useRef(null);

  React.useEffect(() => {
    document.getElementById("side-menu").checked = false;

    if(props.section === "about") {
      if(refAbout.current) {
        refAbout.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "skills") {
      if(refSkills.current) {
        refSkills.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "tools") {
      if(refTools.current) {
        refTools.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "portfolio") {
      if(refProjects.current) {
        refProjects.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    else if(props.section === "contact") {
      if(refContact.current) {
        refContact.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [props]);

  return (
    <div>
      <Splash />
      <About ref={refAbout} />
      <Skills ref={refSkills} />
      <Tools ref={refTools} />
      <Projects ref={refProjects} onProjectSelect={props.handleSelect} />
      <Contact ref={refContact} />
    </div>
  );
}

function IndustryOutput(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <div className="spacer"></div>
      <div className="spacer"></div>

      <div className="explanatory_text">

        <div className="spacer"></div>
        <div className="spacer"></div>
        <h2>What does the U.S. economy produce? A look at national and
        state data</h2>

        <p>The United States has a large and diverse economy that creates
        products and services across a spectrum of different industries.
        Although we often focus on the top-level output of the country&mdash;as
        measured by GDP&mdash;this single number conceals substantial geographic
        differences in the economies of individual states.</p>

        <p>The visualizations below aim to investigate this variability in
        production in more detail. I start first with an overview of the U.S.
        economy and then dive into details about individual states.</p>

        <div className="spacer"></div>
        <h4>National-Level Output</h4>

        <p>The total GDP of the United States was nearly $28 trillion in 2023
        (current dollars). The treemap visualization below shows the breakdown
        of U.S. production across 15 different industry groupings ranging from
        agriculture to manufacturing to transportation (values in millions of
        current dollars; data from <a href="https://www.bea.gov/data/"
        target="_blank" rel="noreferrer">U.S. Bureau of Economic
        Analysis</a>). These industry groupings share the same color when,
        according to the <a href="https://www.census.gov/naics/" target="_blank"
        rel="noreferrer">North American Industry Classification System
        (NAICS)</a>, they are part of the same larger aggregated group (for
        example, wholesale trade, retail trade, and transportation and
        warehousing all have NAICS codes beginning with the number <i>4</i>, so
        they are visualized in the treemap with the same shade of purple).</p>

        <p>The visualization illustrates that, at the national level, five of
        these high-level industry groups were responsible for more than 60
        percent of GDP in 2023. These groups were: (1) finance, insurance, real
        estate, rental, and leasing; (2) professional and business services; (3)
        government and government enterprises; (4) manufacturing; and (5)
        educational services, health care, and social assistance.</p>

        <p>To see a breakdown of the sub-industries contained within each
        high-level group, <b>click</b> on the group. (Note that only the
        groups that have bolded names can be explored in this way.) To zoom
        back out to the higher-level groups, <b>click</b> anywhere within the
        treemap.</p>

      </div>

      <div className="spacer"></div>
      <IndustryOutputMap index={1} useMap={false}/>
      <div className="spacer"></div>

      <div className="explanatory_text">

        <div className="spacer"></div>
        <h4>State-Level Output</h4>

        <p>There is significant variation across states in terms of what they
        produce. The map below illustrates each state's "specialty" by coloring
        the state with its highest-producing industry. If the highest-producing
        industry is "Finance, insurance, real estate, rental, and
        leasing"&mdash;which it is for the vast majority of states&mdash;the map
        instead shows the second-highest-producing industry to give a sense
        of what else the state creates in large quantity.</p>

        <p>The map reveals regional patterns of state specialty, with many
        midwestern, and some southern, states focusing on manufacturing. A
        number of western and northeastern states focus on professional and
        business services. Interestingly, there is only one state&mdash;South
        Dakota&mdash;that has agriculture, forestry, fishing, and hunting as
        either its highest or second-highest producing industry. This is
        similarly the case with Washington and the information industry, Nevada
        with the arts, entertainment, recreation, accommodation, and food
        services industry, and Maine with the educational services, health care,
        and social assistance industry.</p>

        <p>When viewed as percentages, these specialties can be significantly
        higher than the corresponding percentages at the national level. For
        instance, the information industry grouping&mdash;which contains
        sub-industries like motion pictures, telecommunications, and web
        search&mdash;comprised about 5 percent of overall national GDP in 2023
        but nearly 16 percent of Washington's state GDP in that year. As another
        example, the mining, quarrying, and oil and gas extraction industry
        grouping represented about 1.5 percent of national GDP in 2023, while it
        comprised nearly 17 percent of Wyoming's state GDP in that same
        year.</p>

        <p>To get a more detailed view of what each state produces, <b>click</b> on
        a specific state within the map. <b>Click</b> again on the same state
        to zoom back out to the national level. As in the visualization above, <b>click</b> on
        a high-level group within the treemap to see the sub-industries within
        it.</p>
      </div>

      <div className="spacer"></div>
      <IndustryOutputMap index={2} useMap={true}/>
      <div className="spacer"></div>
      <div className="spacer"></div>

      <div className="explanatory_text">

        <h4>Key Takeaways</h4>

        <p>The United States has a large and diverse economy that creates
        products and services across many different industries, though
        national-level statistics mask regional and state-level industrial
        focuses.</p>

        <p>Aside from finance, insurance, real estate, rental, and leasing,
        there are many state specialties, with manufacturing and
        business/professional services being two of the most common. There are
        also a number of states that are unique in their specialty, at least as
        measured by highest-producing (or second-highest-producing) industry in
        2023. The variation in economic focus across regions and states
        certainly impacts the priorities and cultures of states, which we often
        see reflected in their political preferences and policy approaches.</p>
      </div>

      <div className="spacer"></div>
      <div className="spacer"></div>
    </div>
  );
}

class ExploreVisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Exploring the U.S. economic production network</h2>

          <p>As shown in the <Link to="/industry_output">industry output</Link> visualization,
          the United States produces goods and services across a wide variety of
          industries. However, looking at industries in this way suggests that
          industries are isolated and disconnected, which is actually the
          opposite of the reality: industries are fundamentally interconnected
          and interdependent, purchasing from&mdash;and selling to&mdash;each
          other in a complex web of interactions.</p>

          <p>A production network is a conceptualization of the economy that
          explicitly takes account of these linkages, where producing entities
          (such as industries) purchase inputs from other producing entities,
          combine those inputs to create an output, and then sell that output to
          other entities. Production networks provide a unique lens through
          which to understand how changes to one part of the economy can affect
          other parts of the economy.</p>

          <p>As an example, consider the diagram below of four industries. The
          focus industry (shown in red) uses the outputs of three other
          industries: A, B, C. It combines the outputs from these suppliers to
          create its own output, which is then used by other industries
          downstream of it in the production network. To make this more
          concrete, we could think of the focus industry as retail clothing
          stores, which buy clothes from the clothing manufacturing industry
          (Supplier A), rent retail space from the real estate industry
          (Supplier B), and use electricity from the utilities industry
          (Supplier C). The clothing stores then sell the clothes to consumers
          as well as to businesses in other industries.</p>

          <div className="spacer"></div>
          <img className="small_static_image image_no_border" src={diagramSingleIndustry}/>
          <div className="spacer"></div>

          <p>The custom, interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>&mdash;shows
          these relationships for the U.S. economic production network
          as it was in 2015. As in the example above, the producing entities are
          U.S. industries and, specifically, are the 71 industries (including
          government) contained within the U.S. Bureau of Economic
          Analysis' <a href="https://www.bea.gov/industry/input-output-accounts-data" target="_blank" rel="noreferrer">input-output tables</a> for
          2015. As shown in the visualization, each of these industries is
          associated with a code. For example, the code for the "Farms"
          industry is "111CA."</p>

          <p>The visualization works by focusing on a single industry at a time.
          This "focus" industry is shown in red in the center of the
          visualization, while its direct suppliers (i.e., the industries it
          purchases from) are shown in orange above it and its direct customers
          (i.e., the industries it sells to) are shown in green below it. A pair
          of industries may have a mutual dependence such that they purchase
          from, and sell to, each other. As an example, the "Farms" industry
          (code 111CA) purchases from, and sells to, the "Chemical products"
          industry (code 325). Where these types of relationships occur, the
          relevant suppliers/customers are shown in purple to the right of the
          focus industry. For the sake of clarity, the visualization is limited
          to displaying the top five suppliers and the top five customers for
          each focus industry (where, as described, a particular industry's
          supplier may also be its customer, and in that case the
          supplier/customer is shown in purple to the side). <b>Hover</b> over
          an industry to see more information. <b>Click</b> on an industry to
          change the focus.</p>
        </div>
      </div>
    )
  }
}

class ExploreVisBottomText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>A focus industry's direct suppliers and customers (its
          "first-degree" connections) capture just one aspect of its
          connectedness within the broader U.S. production network. The
          visualization also displays some "second-degree" connections:
          a focus industry's suppliers' suppliers as well as its customers'
          customers. These connections are shown in grey at the top and the
          bottom of the visualization, respectively.</p>

          <p>Finally, on the right (or below on smaller screens) is a beeswarm
          plot that illustrates each industry's "upstreamness." Although all
          industries have connections throughout the U.S. production network,
          some industries tend to sell most (or potentially all) of their output
          to final consumers rather than to other industries. Upstreamness is
          essentially a measure of how far away an industry is from this type of
          final demand, where a greater upstreamness value reflects a greater
          distance. For this visualization, I use an upstreamness definition
          based on <a
          href="https://www.aeaweb.org/articles?id=10.1257/aer.102.3.412"
          target="_blank" rel="noreferrer">Antràs et al. (2012)</a>, which yields upstreamness
          values for the 71 industries in 2015 ranging from one (most
          downstream) to 4.5 (most upstream). The most upstream industry is
          "Support activities for mining" (code 213), while there are several
          industries tied for most downstream, including "Hospitals" (code 622),
          "Housing" (code HS), and "Food and beverage stores" (code 445).</p>
        </div>
      </div>
    )
  }
}

class ExploreVis extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreVisTopText />
        <ProductionNetwork graph={this.props.graphData} />
        <ExploreVisBottomText />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/network-visualization" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class ExploreCatTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Categorizing U.S. industries' use of petroleum products</h2>

          <p>As illustrated by the <Link to="/production_network">exploring
          production networks</Link> visualization, the U.S. economy can be
          thought of as a network of industries, where each industry purchases
          inputs from other industries, combines those inputs to create an
          output, and then sells that output to other industries downstream. One
          of the advantages of the production networks conceptualization is that
          it provides a tool to explore the propagation of shocks from one part
          of the economy to another, and one of the key factors determining how
          (and how much) shocks are transmitted is the ability or inability of
          industries to switch among their inputs.</p>

          <p>To explore these dynamics in more detail, I consider here how U.S.
          industries use the outputs of the petroleum products industry<a href="#star">*</a> (such
          as gasoline and diesel fuel) in relation to their other inputs. I
          choose the petroleum products industry both because it is a central
          input in the production network and because its price has often been
          volatile.</p>

          <p>Many industries use petroleum products as a <i>complement</i> to
          other inputs, in the sense that when they use more petroleum products,
          they also tend to use more of those other inputs (and conversely, when
          they use less petroleum products, they also tend to use less of those
          other inputs). As an example, the wood products industry might combine
          the outputs of the forestry industry and petroleum products in a
          complementary way, given that they need fuel to run the machinery that
          converts harvested timber into finished goods (such as lumber and
          flooring).</p>

          <div className="spacer"></div>
          <img className="small_static_image image_no_border" src={diagramComplements}/>
          <div className="spacer"></div>

          <p>Conversely, some industries may use petroleum products as a <i>substitute</i> for
          other inputs, in the sense that when they use more petroleum products,
          they tend to use less of those other inputs (and vice-versa). For
          example, the rail transportation industry might use the outputs of the
          utilities industry (such as electric power) and petroleum products as
          substitutes, given that some of their operations may be able to switch
          between different types of energy sources.</p>

          <div className="spacer"></div>
          <img className="small_static_image image_no_border" src={diagramSubstitutes}/>
          <div className="spacer"></div>

          <h4>Price Shock Propagation: Upstream to Downstream</h4>

          <p>Price changes tend to move from upstream to downstream in the
          production network (that is, from suppliers to customers). The reason
          is that when a producing entity faces a price change, it may pass
          through some of that change to its customers, which then do the same
          with their own customers, and so on. Previous research suggests that
          when inputs are used as complements, it may lead to greater price
          shock propagation than when inputs are used as substitutes. The
          intuition is that, in the complements case, producing entities have
          less flexibility to switch among their inputs when prices fluctuate,
          which may lead them to modify the prices of their own products and
          services more than they otherwise would. This dynamic is then
          compounded over multiple levels in the production network, which leads
          to greater shock propagation.</p>

          <p>The interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>&mdash;shows
          the proportions of U.S. industries' upstream inputs
          that have been categorized as complements to petroleum products,
          substitutes for petroleum products, or neither. The categorizations
          are based on my <a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank" rel="noreferrer">dissertation research</a>,
          which proposes an approach to generate these types of categorizations
          using machine learning and publicly available data (this visualization
          makes use of data from
          the <a href="https://www.bea.gov/" target="_blank" rel="noreferrer">U.S. Bureau of Economic Analysis</a>).
          The visualization takes into account two degrees of upstream
          connections: industries' direct suppliers as well as those suppliers'
          suppliers. <b>Click</b> on the buttons above the chart to sort the
          industries by either the proportion of upstream complements or the
          proportion of upstream substitutes.</p>

          <p>As shown in the chart, a large portion of industries' upstream
          inputs are not categorized as either complements to or substitutes for
          petroleum products. The reason is that, essentially, the approach did
          not detect a strong enough usage pattern for those inputs vis-a-vis
          petroleum products. For additional details, see the full Python, R,
          and D3.js source code for the categorization and visualization
          on <a href="https://github.com/stuartiler/input-categorization" target="_blank" rel="noreferrer">GitHub</a>.</p>
        </div>
      </div>
    )
  }
}

class ExploreCatMiddleText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>One pattern to note is that most industries use petroleum products
          as a complement to at least some of their other inputs. The policy
          implication of this finding is twofold. First, this observation
          provides a partial explanation for why historical oil price increases
          have had substantial economic effects, even if individual industries'
          expenditures on petroleum products have been relatively small:
          complementarity tends to propagate shocks, and such a dynamic will
          only be compounded when many industries use petroleum products as a
          complement to at least some of their other inputs. This suggests that
          policymakers need to pay attention to <i>how</i> industries are using
          petroleum products in addition to <i>how much</i> when putting
          programs in place to anticipate and address potential energy price
          fluctuations. Second, certain individual industries (such as
          particular manufacturing industries) are more likely to be impacted by
          shocks, and targeted policies can be put in place to address those
          effects.</p>

          <div className="spacer"></div>
          <h4>Demand Shock Propagation: Downstream to Upstream</h4>

          <p>Unlike prices, demand changes tend to move from downstream to
          upstream in the production network (that is, from customers to
          suppliers). The reason is that when a producing entity faces a demand
          change, it tends to increase or decrease demand for the inputs
          provided by its suppliers, which those suppliers may then do with
          their own suppliers, and so on.</p>

          <p>When industries demand more petroleum products, they will also tend
          to demand more of the inputs that they use as complements to petroleum
          products. The industries that make those complementary products then
          also experience an increase in demand. Conversely, when industries
          demand less petroleum products, they will tend to demand more of the
          inputs that they use as substitutes for petroleum products. The
          industries that produce those substitute products will then experience
          an increase in demand.</p>

          <p>The visualization below shows the proportions of U.S. industries'
          downstream customers that use their output as complements to petroleum
          products, substitutes for petroleum products, or neither (as
          categorized by the same approach described above). Also similar to
          above, the visualization takes account of two degrees of downstream
          connections: industries' direct customers as well as those customers'
          customers. <b>Click</b> on the buttons above the chart to sort the
          industries by either the proportion of downstream complements or the
          proportion of downstream substitutes.</p>

          <p>The key insight is that industries with a greater proportion of
          downstream complements will tend to experience higher demand when
          there is higher demand for petroleum products (and vice-versa), while
          industries with a greater proportion of downstream substitutes will
          tend to experience higher demand when there is lower demand for
          petroleum products (and vice-versa). Note that a number of industries'
          outputs are used as complements by some of their customers and as
          substitutes by others; depending on the situation, these industries
          may face conflicting forces.</p>
        </div>
      </div>
    )
  }
}

class ExploreCatBottomText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>The key insight is that industries with a greater proportion of
          downstream complements will tend to experience higher demand when
          there is higher demand for petroleum products (and vice-versa), while
          industries with a greater proportion of downstream substitutes will
          tend to experience higher demand when there is lower demand for
          petroleum products (and vice-versa). The policy and strategic
          implications of these findings is relatively straightforward for
          industries that have only (or primarily) downstream complements or
          only (or primarily) downstream substitutes: when oil prices go up, we
          would expect the former to be naturally disadvantaged and the latter
          to be naturally advantaged. However, a number of industries' outputs
          are actually used as complements by some of their customers and as
          substitutes by others; depending on the situation, these industries
          may face conflicting forces.</p>

          <p>Finally, it's worth noting that the interpretation here of the
          downstream categorizations assumes that oil price fluctuations are
          driven by factors external to the U.S. economy, such as by geopolitics
          or other world events. However, given that U.S. industries are major
          consumers of the worldwide supply of petroleum products, increased
          demand for industries that are used as complements to petroleum
          products could actually help to increase the oil price, aside from any
          other international factors. I describe these supply-side versus
          demand-side dynamics in much more detail in my <a
          href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf"
          target="_blank" rel="noreferrer">dissertation research</a>.</p>

          <div className="spacer"></div>
          <h4>Key Takeaways</h4>

          <p>In this project, I used public data and a machine learning approach
          to cateogrize U.S. industries' use of petroleum products as either
          complements to or substitutes for each of their other inputs. The
          first visualization above shows the results on the upstream side of
          industries (looking at industries' suppliers), while the second
          visualization shows the results on the downstream side of indusries
          (looking at industries' customers).</p>

          <p>On the upstream side, I find that most industries use petroleum
          products as a complement to at least some of their inputs, which helps
          to explain why oil price increases have often had impacts throughout
          the economy even when individual industries' expenditures on petroleum
          products have been small. This said, there is variation in the degree
          of upstream complementarity across different industries, which
          suggests that particular industries may be much more exposed to oil
          shocks than others. On the downstream side, some industries' outputs
          tend to be used as complements to petroleum products, others' outputs
          tend to be used as substitutes, and some are used in both ways. We
          would expect the impacts of oil price changes for individual
          industries to depend on a range of factors, including how important
          their various customers are for their overall sales, as well as the
          broader national and international dynamics causing oil prices to
          increase or decrease.</p>
        </div>
      </div>
    )
  }
}

class ExploreCat extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreCatTopText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"upstream"} index={0} />
        <ExploreCatMiddleText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"downstream"} index={1} />
        <ExploreCatBottomText />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="star">*This analysis focuses on the 3-digit NAICS industry
        "petroleum and coal products," which for simplicity I refer to as
        "petroleum products."</div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/input-categorization" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class TetrisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <h2>Tetris with JavaScript and D3.js</h2>

          <p>Below is an interactive Tetris game I built with JavaScript
          and <a href="https://d3js.org/" target="_blank" rel="noreferrer">D3.js v7</a>. Source
          code for game is available
          on <a href="https://github.com/stuartiler/d3-tetris" target="_blank" rel="noreferrer">GitHub</a>.</p>

          <p>Press the arrow keys the on the keyboard to control the falling
          piece. <b>Left</b> and <b>right</b> will move the piece left and
          right, respectively. <b>Up</b> will rotate the piece clockwise,
          and <b>down</b> will push the piece as far down as it can go. (Note
          that scrolling the browser window with the arrow keys has been
          disabled for this page.)</p>

          <p>When one or more horizontal lines are completed, the lines will
          disappear and the remaining blocks will be shifted down. The score is
          increased ten points for every line cleared, with a bonus for the
          number of lines cleared simultaneously. If the board becomes
          completely filled with blocks, it's game over and everything is
          reset.</p>
        </div>
      </div>
    )
  }
}

class PlayTetris extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <TetrisTopText />
        <div className="spacer"></div>
        <Tetris />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for the game is available
        on <a href="https://github.com/stuartiler/d3-tetris" target="_blank" rel="noreferrer">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

function ParallaxImage({scrollY,
                        topLidar,
                        topTIN,
                        topDSM,
                        topSolarRadiation,
                        topNDVI,
                        topSuitableArea}) {

  const [currImg, setCurrImg] = useState(mpStudyAreas);

  useMotionValueEvent(scrollY, "change", (latest) => {

    let adjustment_distance = -50;

    let imgChange =
      latest > topSuitableArea + adjustment_distance ? mpResSuitableArea
      : latest > topNDVI + adjustment_distance ? mpResNDVI
      : latest > topSolarRadiation + adjustment_distance ? mpResSolarRadiation
      : latest > topDSM + adjustment_distance ? mpResDSM
      : latest > topTIN + adjustment_distance ? mpResTIN
      : latest > topLidar + adjustment_distance ? mpResLidar
      : mpStudyAreas;

    setCurrImg(imgChange);

  })

  return (
    <>
      <motion.img className="parallax_image"
                  key={currImg}
                  src={currImg}
                  initial={{opacity: 0.5}}
                  animate={{src: currImg, opacity: 1}}
                  transition={{duration: 1.5}} />
    </>
  );
}

function CommunitySolar(props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    if (refLidar.current) {
      const { top } = refLidar.current.getBoundingClientRect();
      setTopLidar(top);
    }

    if (refTIN.current) {
      const { top } = refTIN.current.getBoundingClientRect();
      setTopTIN(top);
    }

    if (refDSM.current) {
      const { top } = refDSM.current.getBoundingClientRect();
      setTopDSM(top);
    }

    if (refSolarRadiation.current) {
      const { top } = refSolarRadiation.current.getBoundingClientRect();
      setTopSolarRadiation(top);
    }

    if (refNDVI.current) {
      const { top } = refNDVI.current.getBoundingClientRect();
      setTopNDVI(top);
    }

    if (refSuitableArea.current) {
      const { top } = refSuitableArea.current.getBoundingClientRect();
      setTopSuitableArea(top);
    }
  }, []);

  const { scrollY } = useScroll();

  const refLidar = useRef(null);
  const refTIN = useRef(null);
  const refDSM = useRef(null);
  const refSolarRadiation = useRef(null);
  const refNDVI = useRef(null);
  const refSuitableArea = useRef(null);

  const [topLidar, setTopLidar] = useState(0);
  const [topTIN, setTopTIN] = useState(0);
  const [topDSM, setTopDSM] = useState(0);
  const [topSolarRadiation, setTopSolarRadiation] = useState(0);
  const [topNDVI, setTopNDVI] = useState(0);
  const [topSuitableArea, setTopSuitableArea] = useState(0);

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (refLidar.current) {
      const { top } = refLidar.current.getBoundingClientRect();
      setTopLidar(top);
    }

    if (refTIN.current) {
      const { top } = refTIN.current.getBoundingClientRect();
      setTopTIN(top);
    }

    if (refDSM.current) {
      const { top } = refDSM.current.getBoundingClientRect();
      setTopDSM(top);
    }

    if (refSolarRadiation.current) {
      const { top } = refSolarRadiation.current.getBoundingClientRect();
      setTopSolarRadiation(top);
    }

    if (refNDVI.current) {
      const { top } = refNDVI.current.getBoundingClientRect();
      setTopNDVI(top);
    }

    if (refSuitableArea.current) {
      const { top } = refSuitableArea.current.getBoundingClientRect();
      setTopSuitableArea(top);
    }
  }, [windowSize.width]);

  return (
    <div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="explanatory_text">
        <div className="spacer"></div>
        <div className="spacer"></div>

        <h2>Assessing the potential for community solar in Durham, NC</h2>

        <p>For my <a href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf" target="_blank" rel="noreferrer">master's thesis</a> at
        Duke University, I used Geographic Information Systems (GIS) to
        explore the potential for community solar in Durham, North Carolina
        (where Duke is located).</p>

        <p>When we think about rooftop solar panels, we often envision a solar
        system on top of a single-family home.  Community solar is instead a
        "solar-electric system that, through a voluntary program, provides power
        and/or financial benefit to, or is owned by, multiple community members"
        (<a href="https://www.nrel.gov/docs/fy11osti/49930.pdf"
        target="_blank" rel="noreferrer">Coughlin et al., 2010</a>). Two of the primary goals of
        community solar are to increase access to solar energy and to reduce
        initial costs. Other potential benefits include better economies of
        scale, increased flexibility in choosing an optimal solar site, and the
        possibility of local job creation.</p>

        <p>In this research, I explored community solar by: (1) estimating the
        cost and solar potential of hypothetical systems across approximately
        2,000 parcels in Durham; and (2) analyzing the differences in cost and
        solar potential by type of parcel. The idea was to proxy for the
        benefits of community solar as compared to individually-owned
        residential rooftop systems by considering commercial and community
        service parcels on one hand (where community solar projects are more
        likely to be located) and residential parcels on the other.</p>

        <p>Scroll down for a high-level overview of the methods, results, and
        conclusions of the study.</p>

        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="spacer"></div>

        <div className="parallax_block">

          <div className="parallax_text">

            <p>I first chose three study areas: a residential area north
            of Duke University’s East Campus (outlined in green); the greater
            downtown Durham area (outlined in red); and the majority of Duke
            University’s West Campus (outlined in yellow).</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpStudyAreas}/>
            <div className="parallax_image_spacer"/>

            <p>I estimated the cost and solar potential of hypothetical
            photovoltaic systems on the parcels across these areas in a few
            steps. I started by obtaining Light Detection and Ranging (LIDAR)
            data for the study areas from the U.S. Geological Survey. LIDAR data
            provides accurate elevation measurements by using laser technology
            from the air.</p>

            <div className="parallax_spacer" ref={refLidar}></div>

            <p>This image is an example of the LIDAR data points (in yellow) on and
            around an elementary school in the residential study area. Note that
            the data points represent the elevation of the highest object at each
            point, such as a building, tree, or the ground.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResLidar}/>

            <div className="parallax_spacer" ref={refTIN}></div>


            <p>I used these points to create a Triangulated Irregular Network
            (TIN) model: a 3-dimensional surface where individual points are
            connected by lines to generate a set of interconnected triangles. This is the
            first step in moving from a collection of individual points to a
            continuous surface.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResTIN}/>


            <div className="parallax_spacer" ref={refDSM}></div>

            <p>I then converted the  vector-based TIN to a raster-based
            digital surface model (DSM). I performed this conversion using the
            natural neighbors interpolation method, choosing for the output
            raster to have a cell size of one square foot. The resulting DSM
            represents the height of objects in every square foot
            across the three study areas.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResDSM}/>


            <div className="parallax_spacer" ref={refSolarRadiation}></div>

            <p>ArcGIS has a tool called "area solar radiation" that, combined with
            a given DSM, can calculate the aggregate solar radiation at each point
            over a specified period of time. I used this tool to estimate the
            solar radiation across the three study areas for calendar year 2012.
            The calculated radiation is given in watt-hours per square meter.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResSolarRadiation}/>


            <div className="parallax_spacer" ref={refNDVI}></div>

            <p>Before estimating properties of the solar systems that could be
            installed on the study parcels, I first wanted to account for the fact
            that many building rooftops are partially or fully shaded by trees. To
            do this, I made use of aerial imagery from the U.S. Department of
            Agriculture.</p>

            <p>Specifically, in addition to the typical red, green, and blue
            visible color bands, this imagery also included an infrared band
            suitable for calculating the Normalized Difference Vegetation Index
            (NDVI). For biological reasons, plants tend to absorb electromagnetic
            radiation in the visible red light part of the spectrum while
            reflecting near infrared radiation; the NDVI essentially measures the
            difference between these two to identify the amount of vegetation over
            an area.</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResNDVI}/>
            <div className="parallax_image_spacer"/>

            <p>The result is a raster with values ranging from -1 to 1, with
            higher values indicating increased levels of vegetation.</p>


            <div className="parallax_spacer" ref={refSuitableArea}></div>

            <p>With these parts of the analysis completed, I then summed the solar
            radiation across the building footprint(s) on each parcel, ignoring
            any areas (1) where the NDVI indicated the likely presence of
            vegetation and/or (2) where the solar radiation was not above a
            given threshold (based on previous research regarding what would be
            economical for photovoltaic system installation).</p>

            <div className="parallax_image_spacer"/>
            <img className="parallax_static_image" src={mpResSuitableArea}/>
            <div className="parallax_image_spacer"/>

            <p>I also determined the rooftop <i>area</i> associated with each sum,
            which allowed me to estimate the cost of the PV systems taking into
            account economies of scale.</p>

            {windowSize.width > 1600 ? <><div className="spacer"></div><div className="spacer"></div></> : null}
            {windowSize.width > 1900 ? <><div className="spacer"></div><div className="spacer"></div></> : null}
          </div>

          <div className="parallax_image_container">
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <ParallaxImage scrollY={scrollY}
                           topLidar={topLidar}
                           topTIN={topTIN}
                           topDSM={topDSM}
                           topSolarRadiation={topSolarRadiation}
                           topNDVI={topNDVI}
                           topSuitableArea={topSuitableArea} />
          </div>

        </div>

        <div className="spacer"></div>
        {windowSize.width < 1600 ? <div className="spacer"></div>: null}
        {windowSize.width < 1900 ? <div className="spacer"></div>: null}

        <h4>Results of the Study</h4>

        <p>To investigate differences in system costs across different types
        of parcels, I calculated an energy cost ratio for each system:
        the estimated cost of the system (in dollars) divided by the estimated
        annual energy output of the system (in kWh). Note that this metric
        does not represent the cost of electricity over the lifetime of the
        systems but is rather intended as simple proxy for the purpose of
        comparison.</p>

        <p>The averages of the energy cost ratio for each of the three parcel
        types are shown in the table below. The cost is highest for
        residential parcels, and the differences in cost between residential
        parcels and the other two parcel types are statistically significant.
        Along with some of the other results I present in the thesis, this
        suggests that there may be  something to be gained for the average
        residential parcel–in terms of energy received for the investment
        cost–by pooling funds and installing a system that is optimally
        sited on a well-chosen commercial or community service site.</p>

        <div className="spacer"></div>
        <table>
          <tbody>
            <tr>
              <th>Parcel Type</th>
              <th>Number of Parcels</th>
              <th>Average Energy Cost ($/kWh)</th>
            </tr>
            <tr>
              <td>Residential</td>
              <td>1,469</td>
              <td>5.63</td>
            </tr>
            <tr>
              <td>Commercial</td>
              <td>363</td>
              <td>5.24</td>
            </tr>
            <tr>
              <td>Community Service</td>
              <td>82</td>
              <td>5.26</td>
            </tr>
          </tbody>
        </table>
        <div className="spacer"></div>

        <p>In terms of access to renewable energy, I found that approximately
        10 percent of residential parcels did not have rooftop areas suitable
        for PV systems. An additional 18 percent of residential parcels had
        rooftop areas suitable for systems of 1 kilowatt or less. Taken
        together, this suggests that nearly a third of the residential
        parcels I considered in the Durham study areas had little or no access
        to solar energy investment opportunities.</p>

        <div className="spacer"></div>
        <h4>Key Takeaways</h4>

        <p>So overall, could community solar reduce the costs of solar
        photovoltaic systems in Durham? I said likely yes, though it depends
        on which two systems are being compared. Looking across the three
        study areas, larger buildings–which tend to be community service or
        commercial buildings–more often exhibited better cost efficiencies.
        They were not always the best, however, and a homeowner with a
        particularly suitable rooftop would not be gaining anything in terms of
        cost efficiency through participation in a community solar venture.</p>

        <p>Could community solar increase access to the benefits of renewable
        solar energy in Durham? I said very likely yes, though the details
        of the community solar implementation would need to be outlined. In
        any case, for the nearly 30 percent of residential parcels with little
        or no suitable rooftop space, the ability to invest in a community
        solar project would be a huge access improvement over the status quo.
        That would also be the case for individuals that live in apartments
        and for homeowners that have suitable rooftop area but are unwilling
        or unable to invest in a full solar photovoltaic system.</p>
      </div>
      <div className="spacer"></div>
      <div className="spacer"></div>
    </div>
  );

}

class ISONewEngland extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="explanatory_text">
          <div className="spacer"></div>
          <div className="spacer"></div>

          <h2>Exploring ISO New England wind and solar power as it relates to weather and net imports</h2>

          <p>Both as a resident of Massachusetts and as someone who formerly
          worked on climate mitigation for the state, I have an interest in the
          regional New England electricity supply, especially as it pertains to
          renewables and emissions. In this project, I conducted a simple
          exploration of wind and solar power in the <a href="https://www.iso-ne.com/" target="_blank" rel="noreferrer">ISO New England (ISO-NE)</a> electricity
          resource mix for a set of sample days in calendar year 2022. ISO-NE
          serves the six New England states of Connecticut, Maine,
          Massachusetts, New Hampshire, Rhode Island, and Vermont.</p>

          <p>The two main questions I was looking to answer are: (1) is there a
          correlation between weather in the area and the amount of wind and
          solar power dispatched to the ISO-NE grid? and (2) is there a
          relationship between the amount of wind and solar power in the
          resource mix and the amount of power that ISO-NE imports from outside
          of the region?</p>

          <p>To investigate these questions, I retrieved the resource mix for
          the first day of each month in 2022 using ISO-NE's <a href="https://www.iso-ne.com/isoexpress/" target="_blank" rel="noreferrer">ISO Express</a> data
          product. These data points are at the sub-hourly level, which resulted
          in a dataset with 2,357 observations. I then retrieved historical
          weather data measured at Logan International Airport in Boston for
          these same days in 2022 from <a href="https://www.wunderground.com/" target="_blank" rel="noreferrer">Weather Underground</a>, including
          information about wind speed and general conditions. These
          measurements are generally at the hourly level, which resulted in a
          dataset with 360 weather observations.</p>

          <p>I note that these weather data, representing conditions in coastal
          Massachusetts, are likely a better proxy for conditions at wind farms
          in the region than they are for solar farms in the region. In
          addition, for both wind and solar, a single geographic point of
          measurement is of course quite coarse to represent multiple generation
          points.</p>

          <p>With these caveats in mind, I used Microsoft Excel to match each
          row in the power dataset to a row in the weather dataset, where a
          "match" was determined by finding the weather measurement that most
          closely preceded in time each power observation. I then created flags
          to indicate the weather condition (such as cloudy versus fair) as well
          as the season of the year (winter, spring, summer, or fall). A copy of
          the Excel file can be downloaded <a href={isoneExcel} target="_blank"
          rel="noreferrer">here</a>.</p>

          <div className="spacer"></div>
          <h4>Wind Output and Weather</h4>

          <p>I first used Excel to plot the relationship between wind power
          output in megawatts (MW) and wind speed in miles per hour (mph) during
          the sample period (see below; note that the values on the horizontal
          axis should be interpreted as wind speed categories, as there are
          several values missing between 0 mph and 29 mph).</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneWindOutputWindSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind speed, the graph shows the individual power
          data points as well as the average (symbolized by an "x") and the
          range of values between the 25th and 75th percentiles (indicated by
          the green vertical boxes; note that the boxes for the categories 25
          mph and 29 mph appear as horizontal lines because there is only one
          observation for each of those two categories).</p>

          <p>As we might expect, the graph generally suggests that the higher
          the wind speed, the greater the wind power in the ISO-NE resource
          mix. A regression of wind output against wind speed and wind gust
          speed (see below) suggests that about 46 percent of the variation in
          wind output during the sample period can be explained by these two
          speed variables alone.</p>

          <p>I also used Excel to plot the relationship between wind power
          output and wind gust speed during the sample period (as above, the
          values on the horizontal axis should be interpreted as wind speed
          categories, as there are values missing between 0 mph and 40 mph).</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneWindOutputWindGustSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind gust speed, the graph shows the individual
          power data points as well as the average (symbolized by an "x") and
          the range of values between the 25th and 75th percentiles (indicated
          by the orange vertical boxes; note that the box for the category 40
          mph appears as a horizontal line because there is only one observation
          for that category).</p>

          <p>The graph suggests that the higher the wind gust speed, the greater
          the wind power in the ISO-NE resource mix. As noted above, a
          regression of wind output against wind speed and wind gust speed
          suggests that about 46 percent of the variation in wind output during
          the sample period can be explained by those two speed variables. In
          addition, the coefficient on wind gust speed is statistically
          significant even when controlling for wind speed. It may be that gusts
          contribute to generation directly and/or that they are correlated with
          environmental and other variables that are favorable for wind
          production and dispatch.</p>

          <div className="spacer"></div>
          <h4>Solar Output and Weather</h4>

          <p>The graph below illustrates the relationship between average solar
          power output (yellow bars) and the number of fair weather readings in
          each season (blue circles; for this graph, each "fair weather reading"
          is a time period in the sample in which the weather condition was
          recorded as either "Fair" or "Fair / Windy").</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneSolarOutputFairWeather} />
          <div className="spacer"></div>

          <p>The graph suggests that solar power was highest in the ISO-NE
          resource mix during the summer, followed by the spring, fall, and
          winter (in that order). The graph also suggests that, at the seasonal
          level, solar generation is highly correlated with the number of fair
          weather readings.</p>

          <p>Although such a relationship appears at this aggregated seasonal
          level, a regression of solar output against indicators for each fair
          condition ("Fair" and "Fair / Windy") at the individual observation
          (sub-daily) level suggests that these conditions alone do not explain
          much of the solar variation in the dataset (which is likely partially
          due to the geographic coarseness of the weather data, with one weather
          station serving as a proxy for general conditions in the region). This
          said, the coefficients on the indicators are statistically significant
          at the one percent level.</p>

          <div className="spacer"></div>
          <h4>Wind and Solar Output and Net Imports</h4>

          <p>The graph below illustrates the relationship between wind power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneNetImportsWindOutput} />
          <div className="spacer"></div>

          <p>The graph suggests a somewhat negative relationship between these
          two variables (the Pearson correlation coefficient is approximately
          -0.18). This does not necessarily imply that increases in wind power
          "backed out" (i.e., caused a decrease in) net imports on the margin
          during this period, though a regression of net imports on total
          generation, natural gas, nuclear, wind, and solar suggests that this
          type of negative relationship is stronger for wind than it is for
          either natural gas or nuclear.</p>

          <p>ISO-NE's real-time data on CO2 emissions do not include emissions
          estimates for imported electricity "because the resource types
          producing this electricity are unspecified" (see <a href="https://www.iso-ne.com/isoexpress/" target="_blank" rel="noreferrer">ISO Express</a> for
          details). In turn, to the extent that wind power did reduce the need
          for electricity imports, it is unclear how such dynamics impacted
          overall CO2 emissions.</p>

          <p>The graph below illustrates the relationship between solar power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img className="static_image" src={isoneNetImportsSolarOutput} />
          <div className="spacer"></div>

          <p>Even more than in the case of wind, the graph suggests a negative
          relationship between these two variables (the Pearson correlation
          coefficient is approximately -0.42). As above, this does not
          necessarily imply that increases in solar power "backed out" the need
          for net imports on the margin during this period, though a regression
          of net imports on total generation, natural gas, nuclear, wind, and
          solar suggests that such a negative relationship is stronger for solar
          than it is for natural gas, nuclear, or wind. For more information
          about solar power in the ISO-NE resource mix, see <a href="https://www.iso-ne.com/about/what-we-do/in-depth/solar-power-in-new-england-locations-and-impact" target="_blank" rel="noreferrer">Solar Power in New England: Concentration and Impact</a>.</p>

          <p>Also as above, to the extent that solar power did reduce the need
          for electricity imports, it is unclear how such dynamics impacted
          overall CO2 emissions during the sample period as data on the CO2
          intensity of the imports are not available.</p>

          <div className="spacer"></div>
          <h4>Key Takeaways</h4>

          <p>In this project, I used Excel to conduct a basic analysis of wind
          and solar power in the ISO-NE resource mix as it relates to weather
          and net imports. I found that wind speed and wind gust speed together
          accounted for about half of the variation in wind power output during
          the sample period. For solar, individual (sub-daily) weather
          conditions do not explain output variation in the resource mix like the
          observations do for wind, though at the seasonal level, there is a
          strong correlation between conditions and solar output.</p>

          <p>Regressions suggest that wind and solar output are more strongly
          (negatively) associated with net imports than is output of natural gas
          and nuclear, which makes sense given the roles that these latter
          energy sources tend to play in grid (peaking and baseload,
          respectively). However, the implications for CO2 emissions of these
          relationships would require additional data and further
          investigation.</p>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

function GreenButton(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="explanatory_text">
        <div className="spacer"></div>
        <div className="spacer"></div>

        <h3>How to Leverage Green Button Initiative Data for Energy Consumption Apps</h3>

        <p className="italicized_note">Note: this how-to guide was originally published August 17, 2015
        with the byline "Stuart Iler" on the API-focused website
        ProgrammableWeb. ProgrammableWeb was shut down in August 2022, but an
        archived version of the original webpage can be accessed at <a
        href="https://web.archive.org/web/20160911044153/http://www.programmableweb.com/news/how-to-leverage-green-button-initiative-data-energy-consumption-apps/how-to/2015/08/17"
        target="_blank" rel="noreferrer">archive.org</a>. This article may
        contain out-of-date information.</p>

        <div className="spacer"></div>

        <p>For economic, political and environmental reasons, energy use is an
        important consideration for U.S. citizens and policymakers alike. Two
        major drivers of that use—representing about 40% of total consumption
        last year—are the residential and commercial sectors, which require
        energy for a spectrum of daily needs, including heating, air
        conditioning, lighting and electronics.</p>

        <p>At the same time, the greater availability of energy-related data
        provides a unique opportunity for developers to enter the energy space,
        which has historically been the purview of utilities alone. In this
        article, we look at the Green Button initiative, an industry-led effort
        to provide utility customers with easy and secure access to their energy
        usage information. We also consider how developers can leverage these
        data to help customers pursue energy efficiency and other energy
        goals.</p>

        <div className="spacer"></div>
        <h4>The Green Button Initiative</h4>

        <p>The Green Button initiative was launched at the beginning of 2012 and
        represents industry’s response to a White House call to action to
        provide consumer energy data in both people- and computer-friendly
        formats. The initiative follows on the creation of a similar system for
        healthcare information, called Blue Button.</p>

        <p>A growing number of electric utilities have committed to providing
        energy usage data to their customers through the Green Button
        initiative. As of this writing, the program encompassed more than 150
        utilities and service providers and more than 60 million households. As
        we will discuss later in this article, the initiative is also
        benefitting from a growing number of third-party companies creating
        products, services and apps that utilize the exposure of these energy
        data.</p>

        <div className="spacer"></div>
        <h4>Accessing Green Button Data</h4>

        <p>To begin, let’s consider some of the technical components of the
        Green Button system and, specifically, the two available options for
        transferring energy data to applications. For developers, the choice of
        which option to use will largely depend on the target market and how the
        utilities in that geographical area have chosen to implement the Green
        Button program.</p>

        <p>The first option is Green Button Download My Data, a mechanism that
        allows the customer to manually download an energy data file from the
        utility, which can then either be used by customers themselves or, more
        likely, provided to a third-party application for analysis. The second
        option is Green Button Connect My Data, which allows customers to
        authorize third-party companies to receive their usage information
        directly from the utility, streamlining the process for both the
        customer and the application. Among utilities implementing Green Button,
        some offer only the first option (Download My Data), and some offer
        both.</p>

        <p>At the technical core of these data-sharing processes is the Energy
        Services Provider Interface, a standard developed by the North American
        Energy Standards Board with support from the National Institute of
        Standards and Technology and the Smart Grid Interoperability Panel.
        According to the Green Button website, the ESPI standard ensures that
        "energy information can easily be exchanged without requiring developers
        to invest time and money to support proprietary metered data conversion
        technologies."</p>

        <p>Specifically, the ESPI XML schema provides a consistent data
        structure for the transfer of information between utilities (termed
        "data custodians"), their customers ("retail customers") and authorized
        applications ("third parties"). The diagram below illustrates the
        relationships among these three actors and how the Download My Data and
        Connect My Data mechanisms fit within the framework.</p>

        <div className="spacer"></div>
        <img className="small_static_image" src={greenButtonActors} />
        <p className="image_citation">Source: <a
        href="https://dev.greenbuttonalliance.org/technology" target="_blank"
        rel="noreferrer">https://dev.greenbuttonalliance.org/technology</a>.</p>
        <div className="spacer"></div>

        <p>As the diagram shows, Green Button Download My Data involves a
        transaction between the utility and its customers only. For a third
        party to make use of the data, the user would manually provide the
        downloaded file to an application, likely by using a Web interface.</p>

        <p>In contrast, Green Button Connect My Data requires the participation
        of all three actors. The customer must tell the utility about the third
        party and grant the third party access to the information; the customer
        must likewise tell the third party about the utility and provide the
        credentials needed to connect. Once this one-time authorization is
        complete, the third party is able to receive data from the utility
        without further customer action.</p>

        <p>Although a number of utilities offer, or are planning to offer, Green
        Button Connect My Data, two examples are the California utilities
        Pacific Gas & Electric Co. (PG&E) and San Diego Gas & Electric Co.
        (SDG&E). The first phase of PG&E's product, which includes access to
        electricity usage data, was rolled out earlier this year, and a second
        phase is planned for the fourth quarter. SDG&E announced the rollout of
        its Connect My Data platform near the end of 2012. Both companies are
        soliciting registrations/applications from developers and third parties
        looking to create products using their customers' data.</p>

        <p>Aside from working directly with a utility, developers can also
        leverage the Green Button initiative’s API Sandbox to create and test
        their applications. As part of Green Button’s Developer Sandbox, the API
        Sandbox provides a testing ground for a number of RESTful methods
        relating to server status, applications, authorizations and, of course,
        energy data itself. Finally, additional information relevant to
        developers can be found on the Green Button website, including videos,
        reference documents and sample data files. A developer's guide is also
        available.</p>

        <div className="spacer"></div>
        <h4>Processing Green Button Data</h4>

        <p>Before considering a couple of applications that utilize these data,
        let’s take a closer look at the Green Button data format itself. The
        data are structured as atom+xml streams of Energy Usage Information
        (EUI) wrapped within an Atom feed. An example adapted from the Green
        Button documentation is the following (ellipses are inserted where
        additional content would be present):</p>

        <div className="spacer"></div>
        <p className="code_block">
          &lt;feed&gt;<br/>
                 &nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                 &nbsp;&nbsp;&nbsp;&nbsp;&lt;entry&gt;<br/>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;link/&gt;<br/>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;content&gt;<br/>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;espi-resource/&gt;<br/>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/content&gt;<br/>
                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                 &nbsp;&nbsp;&nbsp;&nbsp;&lt;/entry&gt;<br/>
                 &nbsp;&nbsp;&nbsp;&nbsp;...<br/>
          &lt;/feed&gt;
        </p>
        <div className="spacer"></div>

        <p>Energy-related information, which follows the ESPI standard, is
        always placed within the &lt;feed&gt; tags. As shown above,
        &lt;entry&gt; tags are used for further organization. Actual energy data
        (such as electricity meter readings) appear within &lt;content> tags,
        and &lt;link&gt; tags can be used to represent a number of
        relationships.</p>

        <p>Although a variety of EUI can be contained in one of these feeds, for
        the purposes of this article, we'll begin by focusing on two elements:
        &lt;IntervalBlock&gt; and &lt;IntervalReading&gt;. According to the
        Green Button developer's guide, "IntervalBlocks are the primary data
        carrier within the Green Button data. IntervalBlocks may have one or
        more Intervals, each with a start and duration, as well as the specific
        IntervalReading." As mentioned in the previous section, the Green Button
        website provides several example XML data files. The following is an
        excerpt of a file containing one month’s worth of hourly energy data
        organized into one-day blocks. (Again, ellipses have been inserted where
        content has been shortened or removed.)</p>

        <div className="spacer"></div>
        <p className="code_block">
        &lt;feed&gt;<br/>
        ...<br/>
        &lt;entry&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;id&gt;urn:uuid:39498ED9-06D8-4873-862E-A7AC3E6CF357&lt;/id&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;link rel="self" href="https://..."/&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;link rel="up" href="https://..."/&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;title/&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;content&gt;<br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IntervalBlock xmlns="http://naesb.org/espi"&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;interval&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;duration&gt;86400&lt;/duration&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;start&gt;1401595200&lt;/start&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- start date: 6/1/2014 4:00:00 AM --&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/interval&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IntervalReading&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;timePeriod&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;duration&gt;3600&lt;/duration&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;start&gt;1401595200&lt;/start&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- 6/1/2014 4:00:00 AM  --&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/timePeriod&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;value&gt;776&lt;/value&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IntervalReading&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;IntervalReading&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;timePeriod&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;duration&gt;3600&lt;/duration&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;start&gt;1401598800&lt;/start&gt;<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- 6/1/2014 5:00:00 AM  --&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/timePeriod&gt;<br/>
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;value&gt;291&lt;/value&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IntervalReading&gt;<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...<br/>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/IntervalBlock&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;/content&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;published&gt;2014-06-02T04:00:00Z&lt;/published&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;updated&gt;2014-06-02T04:00:00Z&lt;/updated&gt;<br/>
        &lt;/entry&gt;<br/>
        ...<br/>
        &lt;/feed&gt;<br/>
        </p>
        <div className="spacer"></div>

        <p>In this example, the &lt;content&gt; tag contains a single
        &lt;IntervalBlock&gt;. The &lt;interval&gt; tag indicates that the block
        begins at 4 a.m. on June 1, 2014, and holds data covering a 24-hour
        period. As with much of the content in the Green Button data files, to
        decipher the numerical values within the &lt;interval&gt; tag, a check
        against the XML schema is helpful: The &lt;duration&gt; is in seconds,
        and the &lt;start&gt; is of type TimeType–that is, the number of seconds
        since 0 hours, 0 minutes, 0 seconds on January 1, 1970.</p>

        <p>After the &lt;interval&gt; tag, there are 24 &lt;IntervalReading&gt;
        tags, two of which are shown above. As with the &lt;IntervalBlock&gt;,
        each &lt;IntervalReading&gt; has a start time and a duration. In this
        case, the first reading begins at 4 a.m. and lasts for one hour; the
        second reading starts at 5 a.m. and extends through the next hour; and
        so on. The &lt;value&gt; tags contain the energy usage associated with
        each reading as measured in watt-hours (more on this below). In this
        example, 776 watt-hours were consumed in the first hour and 291
        watt-hours were consumed in the second.</p>

        <p>The ESPI XML schema specifies much more than just the
        &lt;IntervalBlock&gt; and &lt;IntervalReading&gt; elements, of course.
        For instance, the &lt;UsagePoint&gt; tag indicates the "logical point on
        a network at which consumption or production is either physically
        measured (e.g., metered) or estimated (e.g., unmetered street lights)."
        Within &lt;UsagePoint&gt;, subelements such as &lt;roleFlags&gt; can
        specify a range of additional information, including whether or not the
        usage point is the point of delivery for the premises, whether the usage
        is for an electric vehicle, or whether the usage point represents a
        distributed resource — such as solar panels — that can provide power to
        the electric grid. In our example, the &lt;UsagePoint&gt; only contains
        information about the kind of utility service; that is, a &lt;kind&gt;
        tag containing "0," indicating that the consumption is electricity:</p>

        <div className="spacer"></div>
        <p className="code_block">
        ...<br/>
        &lt;content&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;UsagePoint xmlns="http://naesb.org/espi"&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ServiceCategory&gt;<br/>
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;kind&gt;0&lt;/kind&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ServiceCategory&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;/UsagePoint&gt;<br/>
        &lt;/content&gt;<br/>
        ...
        </p>
        <div className="spacer"></div>

        <p>Another identifying element is &lt;ReadingType&gt;, the contents of
        which (for our example) are shown below. Among other characteristics,
        the &lt;flowDirection&gt; of "1" indicates that the file’s measurements
        are for electricity being delivered by the utility to the consumer,
        rather than the other way around. We can also see that the unit of
        measurement for the readings is real energy in watt-hours (&lt;uom&gt;
        is "72" and &lt;powerOfTenMultiplier&gt; is "0").</p>

        <div className="spacer"></div>
        <p className="code_block">
        ...<br/>
        &lt;content&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;ReadingType xmlns="http://naesb.org/espi"&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;accumulationBehaviour&gt;4&lt;/accumulationBehaviour&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;commodity&gt;1&lt;/commodity&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;dataQualifier&gt;12&lt;/dataQualifier&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;flowDirection&gt;1&lt;/flowDirection&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;intervalLength&gt;3600&lt;/intervalLength&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;kind&gt;12&lt;/kind&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;phase&gt;769&lt;/phase&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;powerOfTenMultiplier&gt;0&lt;/powerOfTenMultiplier&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;timeAttribute&gt;0&lt;/timeAttribute&gt;<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;uom&gt;72&lt;/uom&gt;<br/>
               &nbsp;&nbsp;&nbsp;&nbsp;&lt;/ReadingType&gt;<br/>
        &lt;/content&gt;<br/>
        ...
        </p>
        <div className="spacer"></div>

        <p>Although there isn’t room in this article to cover the whole ESPI XML
        schema, it’s clear that the data format provides plenty of flexibility
        for different applications. And in the form of a customer's actual data
        file, it creates the basis for a variety of consumer-focused energy
        insights.</p>

        <p>Finally, it should be noted that the Green Button website also
        provides, in addition to sample XML files, two sample style sheets to
        transform the often-cryptic XML files into readable layouts. To utilize
        these style sheets, simply download them into the same directory as the
        XML file(s) to view, and open the XML file(s) in a browser such as
        Firefox. For instance, the sample Green Button XML data file used
        throughout this article is associated with the first of these two style
        sheets by default (named GreenButtonDataStyleSheet.xslt), as given by
        this line near the top of the XML file:</p>

        <div className="spacer"></div>
        <p className="code_block">
        &lt;?xml-stylesheet type="text/xsl" href="GreenButtonDataStyleSheet.xslt"?&gt;
        </p>
        <div className="spacer"></div>

        <p>Opening the file in the browser yields the formatting shown below.
        Note that additional information in the XML file has allowed conversion
        of the time stamps to local time; whereas the raw energy data (above)
        began at 4 a.m., the local time now begins at midnight (see the
        &lt;LocalTimeParameters&gt; tag for more information). The values are
        also now shown in kilowatt-hours rather than watt-hours.</p>

        <div className="spacer"></div>
        <img className="static_image" src={stylesheetScreenshot1} />
        <div className="spacer"></div>

        <p>The second style sheet (GreenButtonDataStyleSheetCSV.xslt) displays
        the file in comma-separated-values format. Changing the XML file’s style
        sheet reference and reloading in the browser yields the following:</p>

        <div className="spacer"></div>
        <img className="static_image" src={stylesheetScreenshot2} />
        <div className="spacer"></div>

        <p>Depending on the way an application is developed, such a format may
        be more conducive to processing. Also note that some utilities may offer
        a direct download of energy data in comma-separated-values format in
        addition to the ESPI XML format.</p>

        <div className="spacer"></div>
        <h4>Leveraging Green Button Data to Provide Insight</h4>

        <p>Once developers have gained access to a customer’s energy information and have parsed the XML data as needed, the final step is, of course, to leverage the Green Button data to provide insights for the customer. Although writing an application to accomplish this goal is beyond the scope of this article, it may be valuable to consider a couple of examples.</p>

        <p>One is the Compare My Energy application hosted on the Green Button
        website. Developed by EnerNex LLC, Compare My Energy allows the
        side-by-side comparison of energy usage from two different periods. The
        user is invited to experiment with the interface using a preloaded
        sample Green Button data file (shown below), but the user may also
        upload his or her own Green Button file. In the example, the customer’s
        energy usage for the first week of June 2011 is compared to the usage
        for the first week of July 2011:</p>

        <div className="spacer"></div>
        <img className="small_static_image" src={compareMyEnergy} />
        <p className="image_citation">Source: this application was previously
        hosted on <a href="https://greenbuttondata.org/" target="_blank"
        rel="noreferrer">greenbuttondata.org</a> but appears to no longer be
        available.</p>
        <div className="spacer"></div>

        <p>There are, of course, numerous other applications that utilize Green
        Button energy data. For example, PEV4me.com interfaces with both PG&E
        and SDG&E and targets users who either own or are considering buying
        plug-in electric vehicles. The application provides information on the
        potential cost of charging a plug-in electric vehicle, how those costs
        compare with driving a typical internal combustion vehicle and which
        utility electricity plans might be most suitable.</p>

        <p>A Web search yields many more examples, and the website OpenEI
        provides a catalog of apps that interface with the Green Button
        standard.</p>

        <div className="spacer"></div>
        <h4>The Possibilities Are Many</h4>

        <p>This article has provided a brief overview of how developers can
        access Green Button data, interpret it and leverage it to provide
        customers with energy insights that they likely can't obtain from their
        monthly energy bills.</p>

        <p>Although the Green Button initiative is relatively new, it has come
        together rapidly. The standard itself continues to develop, and the
        creation this year of the Green Button Alliance, a nonprofit
        organization with the goal "to foster the development, compliance, and
        wide-spread adoption of the Green Button standard," demonstrates the
        commitment of companies, utilities and government entities to the
        success of the program.</p>

        <p>Ongoing work by all of these parties highlights the innovation,
        collaboration and value that can come from implementation of this type
        of data standard. As the number of utilities participating in the
        program continues to increase, and in particular, as more of them
        implement Green Button Connect My Data and its associated APIs, the
        greater the opportunities for developers to enter this emerging
        market.</p>

        <p>In all, the standardization of methods to structure and share
        customers' energy data will be an important first step in creating an
        environment where novel and useful energy applications can thrive.</p>

        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    </div>
  );
}

function ConsumingYourOwnAPIs(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <div className="explanatory_text">
        <div className="spacer"></div>
        <div className="spacer"></div>

        <h3>NPR Touts Benefits of Consuming Your Own APIs</h3>

        <p className="italicized_note">Note: this article was originally
        published February 18, 2014 with the byline "Stuart Iler,
        ProgrammableWeb Staff" on the API-focused website ProgrammableWeb.
        ProgrammableWeb was shut down in August 2022, but an archived version of
        the original webpage can be accessed at <a
        href="https://web.archive.org/web/20160403002537/http://www.programmableweb.com/news/npr-touts-benefits-consuming-your-own-apis/2014/02/18"
        target="_blank" rel="noreferrer">archive.org</a>. This article may
        contain out-of-date information.</p>

        <div className="spacer"></div>

        <p>The idea of “eating one's own dog food,” or dogfooding, goes back a
        long way—most notably, with companies using their own software to
        demonstrate the technology's performance and value. Today, many
        companies are dogfooding their APIs, not only to demonstrate the APIs'
        benefits but also to put the technology through its paces over time.</p>

        <p>I recently sat down with National Public Radio’s (NPR's) Javaun
        Moradi, product manager of APIs, to discuss the concept of dogfooding
        and explore how engineering teams that develop APIs can stay at the
        cutting edge within their organizations. Joining us for part of the
        interview was Irakli Nadareishvili, NPR's director of engineering,
        digital media.</p>

        <p>While there are different schools of thought around companies
        consuming their own APIs, Moradi's stance is clear: “We have pretty
        strong opinions that you need to be the consumer of your API.”</p>

        <p>Moradi's position is based mainly on two principles.</p>

        <p>First, an API needs to be consumed internally for the API to stay at
        the forefront of the organization. “If it doesn’t scale, if there are
        bugs in it, if the critical applications aren’t using it, then [the
        developers] don’t care, so nobody in the business cares,” said
        Moradi.</p>

        <p>Second, internal users know how to maximize the value of the API and
        the data it provides, leading to a better API over the longer term. "If
        your organization is writing APIs for your own purposes, it’s a fallacy
        to assume that if you can’t think of what to do with that data or
        service, then somebody else will, because you know the most about your
        business, your organization, and the market landscape," said Moradi. "If
        it’s not valuable to you, it’s not valuable to anybody else.”</p>

        <p>Nadareishvili summed up this last point a single sentence: “If you
        cannot figure out what to do with your data, you shouldn’t always expect
        somebody else to figure it out.”</p>

        <div className="spacer"></div>
        <h4>APIs with a Purpose</h4>

        <p>Moradi and Nadareishvili said businesses must be very clear about the
        intended use of and audience for their APIs.</p>

        <p>According to Moradi, APIs should be created when there's a business
        use; organizations shouldn't be developing public APIs for the sake of
        being public, unless that's actually part of the organization's
        mission.</p>

        <p>Nadareishvili noted that there is a “broad spectrum” of use cases for
        developing APIs: At one extreme is liberating data through APIs, and at
        the other extreme is APIs as a business. He pointed to the World Bank,
        which provides access to more than 8,000 country and development
        indicators through its API, as an example of the former. Twilio, a
        company that enables a gamut of cellular voice, messaging, and other
        functionality through its set of APIs, is an example of the latter.</p>

        <p>Moradi noted that because NPR is a public trust, “there will always
        be a public component [to our APIs], and to the extent that we can
        release content, we will and we should.”</p>

        <p>At the same time, there is a dichotomy between public interest in
        NPR's APIs and actual usage: “If you look at our API over the years,
        probably 95% of the keys are public keys, and the other 5% are NPR and
        stations; but if you look at the usage, it’s exactly flipped: 95% of the
        usage is NPR stations, and 5% is the public,” said Moradi.</p>

        <p>In the process of trying to maintain and improve an API, feedback
        from the public and from internal groups can be complementary. Given
        that many of NPR's APIs are (or were) private for a long time before
        being released to the public, the most valuable public feedback is often
        not related to new use cases or to bugs. Instead, Moradi said, the
        public can pose valuable questions about API design, highlight areas
        that might be confusing, or emphasize the need for better
        documentation.</p>

        <div className="spacer"></div>
        <h4>Staying at the Cutting Edge</h4>

        <p>One of the most interesting elements in our discussion was the
        question of how API teams can remain at the cutting edge within their
        organizations. For Moradi and Nadareishvili, this goal is so entwined
        with “eating your own” that it can, in fact, almost be viewed as part of
        the same larger process.</p>

        <p>Moradi explained that, at one point, the NPR development team was
        much more focused on the public: “Let’s unleash the masses and let a
        thousand flowers bloom, and see what people will think of.”</p>

        <p>And although that model is valuable, Moradi said, most of the
        innovation for an API happens internally. The internal constituency
        knows the business, and there is no substitute for that. For example, he
        said, any user can learn an API in 15 minutes or so, but an internal
        user will use the API with all of the knowledge and context that comes
        from working for the company.</p>

        <div className="spacer"></div>
        <h4>Writing the API First</h4>

        <p>Staying at the cutting edge involves more than internal consumption,
        however.</p>

        <p>“It’s not just eating your own; it’s having an API-centric
        architecture, which is: If something is valuable, build the API first,”
        Moradi said.</p>

        <p>He recalled a situation in which NPR's digital media team was looking
        to implement search functionality on top of an existing library
        database. The database already contained a wealth of metadata about who
        had spoken on what NPR shows, the topics the shows had featured, and
        other related information. The search application would have linked
        directly to this dataset, and members of the digital media group were
        prepared to move forward with that proposition, while also considering
        the development of an API as a parallel activity. But Moradi and his
        team intercepted the process, successfully promoting the idea that the
        API should be built first, with the search application as the initial
        consumer of the API.</p>

        <p>“That API is solid,” Moradi said. “The biggest use of this is every
        producer and reporter in the building.” Every query against the database
        that occurs each day is going through the API, and although the API is
        not public, it's created a standardized interface that may, at some
        point, be leveraged for other use cases.</p>

        <p>In line with his overall theme, though, Moradi is convinced that the
        most important use has already been found: “It’s possible that there
        will be a more important user of that API, but I don’t think so.”</p>

        <p>In general, most of NPR's APIs begin as private, internal endeavors.
        But Moradi stressed that no matter who the intended audience, all of
        NPR's APIs are held up to a high standard, with developers striving to
        provide ease of use: “If we hold it to that public level of usability,
        APIs can be beautiful. ... The plus side, if we do decide to open it up
        [to the public], is it’s ready to go. We write everything as an API
        first: We consume it, and we become its first customer. Rarely do you
        ever do something and need it in just one place; so that’s a rule for
        us.”</p>

        <div className="spacer"></div>
        <h4>Keeping Up With the Organization</h4>

        <p>A second component of staying at the cutting edge, once an API is
        being used, is the ability to provide stability and to adjust to
        changing business and organizational needs. “Once you’re at the cutting
        edge, you have to keep it up; you think, ‘OK, don’t break anything,’”
        said Moradi.</p>

        <p>At the same time, there are periods when users will need new
        functionality, and they will need it on a timeline that syncs with
        product release schedules. Supporting these requirements&mdash;and
        more&mdash;will help IT and development teams to not only support the
        business but also to drive the business.</p>

        <p>“If all you are is the stability people, and [the business side
        says,] ‘We need this feature, we need this locator … How long will it
        take to do this?’ If you tell them two months, a month, or sometimes
        even more than a week, they bypass you. You have a choice as an API
        provider: You’re always going to be stability pipes, but you have a
        chance&mdash;and it’s a huge chance&mdash;to lead the organization.”</p>

        <p>Moradi notes that API developers can go even a step further and
        actually be a part of the design and user interface process.</p>

        <p>He described occasions when other teams were designing the look of
        audio listening apps&mdash;sketching on paper, drawing layout and buttons.
        Moradi and his team listened to these ideas and thought about the
        necessary data back end: New or existing API services that spanned user
        login, ratings, location and content delivery.</p>

        <p>In these instances, the API team provided a commitment to supply the
        services within the necessary timeframe, allowing them not only to serve
        their constituency, but to hold an important seat at the table.</p>

        <p>"The API team is saying, ‘These are the four services that are going
        to be created, the new endpoints, by the time you’re ready to ship,’ and
        that is a beautiful place to be," said Moradi. "At that point, you are
        no longer just going to the mobile team and saying, ‘We can hit your
        deadline.’ When it works like that, you're where every API team should
        strive to be.”</p>

        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <TopBar />
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/about" element={<MainPage section={"about"} />}/>
        <Route path="/skills" element={<MainPage section={"skills"} />}/>
        <Route path="/tools" element={<MainPage section={"tools"} />}/>
        <Route path="/portfolio" element={<MainPage section={"portfolio"} />}/>
        <Route path="/contact" element={<MainPage section={"contact"} />}/>
        <Route path="/industry_output" element={<IndustryOutput />}/>
        <Route path="/production_network" element={<ExploreVis graphData={graphData} />}/>
        <Route path="/input_categorization" element={<ExploreCat catData={categoryData} />}/>
        <Route path="/community_solar" element={<CommunitySolar />}/>
        <Route path="/isone_weather" element={<ISONewEngland />}/>
        <Route path="/tetris" element={<PlayTetris />}/>
        <Route path="/green_button" element={<GreenButton />}/>
        <Route path="/consuming_your_own_apis" element={<ConsumingYourOwnAPIs />}/>
      </Routes>
      <BottomBar />
    </div>
  );
}

export default App;
